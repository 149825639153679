import z from "zod";

export const UserCredentialsSchema = z.object({
  useremail: z
    .string()
    .min(1, "E-mail address is required")
    .email({ message: "Invalid email address" }),
  password: z.string().min(1, "Password is required"),
  newPassword: z.string().min(1, "New password is required"),
  confirmPassword: z.string().min(1, "Confirm password is required"),
});
// .refine((data) => data.password === data.confirmPassword, {
//   message: "Passwords do not match",
//   path: ["confirmPassword"],
// })
// .refine((data) => data.newPassword === data.confirmPassword, {
//   message: "Passwords do not match",
//   path: ["confirmPassword"],
// });

export type UserCredentialsType = z.infer<typeof UserCredentialsSchema>;

export const ForgotPasswordSchema = UserCredentialsSchema.pick({
  useremail: true,
});

export type ForgotPasswordType = z.infer<typeof ForgotPasswordSchema>;
