import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HTTPApi from "i18next-http-backend";

i18next.use(initReactI18next).use(HTTPApi).use(LanguageDetector).init({
  debug: true,
  partialBundledLanguages: true,
  ns: [],
  resources: {},
  load: 'languageOnly',
  whitelist: ['en', 'fr'],
  fallbackLng: "en",
});

export default i18next;
